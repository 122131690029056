*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

._7_rvlW_selected {
  border: 2px solid #000;
}

._7_rvlW_growShrink {
  transition: all .4s;
}

._7_rvlW_growShrink[data-state="on"] {
  transform: scale(1.05);
}

.J8PHqW_shake {
  animation-name: J8PHqW_tilt-shaking;
  animation-duration: .1s;
  animation-iteration-count: 5;
  animation-delay: .2s;
}

@keyframes J8PHqW_shake-horizontal {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-1%);
  }

  50% {
    transform: translateX(1%);
  }

  75% {
    transform: translateX(-1%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes J8PHqW_tilt-shaking {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(1deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(0);
  }
}

/*# sourceMappingURL=index.e9b9e00f.css.map */
